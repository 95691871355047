<template>
	<div>

		<div class="grid grid-cols-2 mb-4">
			<div class="col-span-1">
				<h2 class="text-2xl font-bold">All Statements</h2>
			</div>
			<div class="col-span-1">
				<div class="relative">
					<input
						type="search"
						name="search"
						class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-xs w-full"
						placeholder="Search for month, year etc."
						v-model="statements.query">
					<ion-icon name="search-outline" class="text-blue-200 absolute z-10 text-lg ml-3 left-0" style="top: 50%; transform: translateY(-50%);"></ion-icon>
				</div>
			</div>
		</div>

		<div class="border border-solid border-blue-200 overflow-hidden">
			<datatable
				:data="statements.data"
				:columns="statements.columns"
				:actions="statements.actions"
				:dropdown="false"
				:loading="statements.loading"
				:query="statements.query"
				ref="table"
			/>
		</div>

    <modal className="w-full md:w-3/5 xl:w-5/10 pt-10" ref="viewModal">
      
      <h4 class="text-xl font-bold mb-6">Account Statement</h4>

      <template v-if="statements.selected">
        <div class="grid grid-cols-1 md:grid-cols-2 border-b border-t border-blue-200">
          <div class="col-span-1 p-4">
            <div class="opacity-75 text-sm font-medium mb-1">Requested By</div>
            <div class="text-gray-500 text-sm font-light flex flex-row items-center">
              <ion-icon name="person-outline" class="text-lg mr-2" style="width: 17px; flex: 0 0 17px;"></ion-icon>
              {{ statements.selected }}
            </div>
          </div>
          <div class="col-span-1 p-4 border-t md:border-t-0 md:border-l border-blue-200">
            <div class="opacity-75 text-sm font-medium mb-1">Category</div>
            <div class="text-gray-500 text-sm font-light flex flex-row items-center">
              <ion-icon name="albums-outline" class="text-lg mr-2" style="width: 17px; flex: 0 0 17px;"></ion-icon>
              {{ statements.selected }}
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200" style="margin-top: -1px">
          <div class="col-span-1 p-4">
            <div class="opacity-75 text-sm font-medium mb-1">Transfer Label</div>
            <div class="text-gray-500 text-sm font-light flex flex-row items-center">
              <ion-icon name="pricetag-outline" class="text-lg mr-2" style="width: 17px; flex: 0 0 17px;"></ion-icon>
              {{ statements.selected }}
            </div>
          </div>
          <div class="col-span-1 p-4 border-t md:border-t-0 md:border-l border-blue-200">
            <div class="opacity-75 text-sm font-medium mb-1">Transfer Description</div>
            <div class="text-gray-500 text-sm font-light flex flex-row items-center">
              <ion-icon name="reader-outline" class="text-lg mr-2" style="width: 17px; flex: 0 0 17px;"></ion-icon>
              {{ statements.selected }}
            </div>
          </div>
        </div>


        <div class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200" style="margin-top: -1px">
          <div class="col-span-1 p-4">
            <div class="opacity-75 text-sm font-medium mb-1">Transfer Amount</div>
            <div class="text-gray-500 text-sm font-light flex flex-row items-center">
              <ion-icon name="cash-outline" class="text-lg mr-2" style="width: 17px; flex: 0 0 17px;"></ion-icon>
              {{ statements.selected }}
            </div>
          </div>
          <div class="col-span-1 p-4 border-t md:border-t-0 md:border-l border-blue-200">
            <div class="opacity-75 text-sm font-medium mb-1">Transfer Status</div>
            <div class="text-gray-500 text-sm font-light flex flex-row items-center">
              <ion-icon name="square-outline" class="text-lg mr-2" style="width: 17px; flex: 0 0 17px;"></ion-icon>
              {{ statements.selected }}
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200" style="margin-top: -1px">
          <div class="col-span-1 p-4">
            <div class="opacity-75 text-sm font-medium mb-1">Beneficiary</div>
            <div class="text-gray-500 text-sm font-light flex flex-row items-center">
              <ion-icon name="happy-outline" class="text-lg mr-2" style="width: 17px; flex: 0 0 17px;"></ion-icon>
              {{ statements.selected }}
            </div>
          </div>
          <div class="col-span-1 p-4 border-t md:border-t-0 md:border-l border-blue-200">
            <div class="opacity-75 text-sm font-medium mb-1">Request Date</div>
            <div class="text-gray-500 text-sm font-light flex flex-row items-center">
              <ion-icon name="calendar-outline" class="text-lg mr-2" style="width: 17px; flex: 0 0 17px;"></ion-icon>
              {{ statements.selected }}
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200 mb-10" style="margin-top: -1px">
          <div class="col-span-1 p-4">
            <div class="opacity-75 text-sm font-medium mb-1">Beneficiary Account Number</div>
            <div class="text-gray-500 text-sm font-light flex flex-row items-center">
              <ion-icon name="file-tray-full-outline" class="text-lg mr-2" style="width: 17px; flex: 0 0 17px;"></ion-icon>
              {{ statements.selected }}
            </div>
          </div>
          <div class="col-span-1 p-4 border-t md:border-t-0 md:border-l border-blue-200">
            <div class="opacity-75 text-sm font-medium mb-1">Beneficiary Bank</div>
            <div class="text-gray-500 text-sm font-light flex flex-row items-center">
              <ion-icon name="business-outline" class="text-lg mr-2" style="width: 17px; flex: 0 0 17px;"></ion-icon>
              {{ statements.selected }}
            </div>
          </div>
        </div>

        <!-- <template v-if="getFormError(form)">
          <div class="alert alert-red-soft mb-16">
            <div class="alert-icon">!</div>
            <span>{{ getFormError(form) }}</span>
          </div>
        </template> -->

        <!-- <div class="text-center" v-if="statements.selected.status === 'pending'">
          <div class="mb-7">
            <div class="text-sm text-gray-700 mb-2">Select the wallet to bill from.</div>
            <custom-select
              :options="walletOptions"
              v-model="form.data.wallet.value"
              class="pl-5 pr-8 py-2 border border-blue-200 text-gray-700 rounded text-left inline-block text-sm custom-select"
            />
          </div>
          <div>
            <button type="button" class="btn btn-blue mr-3" @click.prevent="approve" :disabled="form.loading">
              <span v-if="form.loading">Approving</span>
              <span v-else>Approve</span>
            </button>
            <button type="button" class="btn btn-red" :disabled="form.loading">
              Decline
            </button>
          </div>
        </div> -->

      </template>
      <template v-else>
        <div class="text-sm text-gray-700 mb-10">Please select a transfer to view.</div>
      </template>

    </modal>

	</div>
</template>
<script>
	export default {
		data() {
			return {
				statements: this.$options.resource([], {
					columns: [
						{
							name: 'period',
							th: 'Period',
							render: statement => {
								return (new Date(statement.start_date)).format('dd M Y') +' - '+(new Date(statement.end_date)).format('dd M Y')
							}
						},
						{
							name: 'aggregate_interest',
							th: 'Total Interest'
						},
						{
							name: 'aggregate_balance',
							th: 'Total Balance'
						},
						{
							name: 'total_outstanding',
							th: 'Amount Outstanding'
						},
						{
							name: 'total_amount_paid',
							th: 'Amount Paid'
						},
						{
							name: 'status',
							th: 'Status',
							render: statement => {
								var string = '';
								switch (statement.status) {
									case 'open':
										string += '<div class="badge badge-blue-soft-outline">Open</div>';
										break;
									case 'closed':
										string += '<div class="badge badge-blue-soft-outline">Closed</div>';
										break;
								}

								string += ' - ';

								switch (statement.condition) {
									case 'paid':
										string += '<div class="badge badge-green-soft-outline">Paid</div>';
										break;
									case 'part-paid':
										string += '<div class="badge badge-blue-soft-outline">Part Paid</div>';
										break;
									case 'unpaid':
										string += '<div class="badge badge-orange-soft-outline">Unpaid</div>';
										break;
								}
								return string;
							}
						},
					],
					actions: [
						{
							text: 'download',
							class: 'btn btn-blue btn-sm',
							action: this.downloadStatement
						}
					],
					selected: '',
					query: ''
				}),
			}
		},
		beforeMount() {
			this.getStatements();
		},
		methods: {
			click(statement) {
				this.statements.selected = statement;
				this.$refs.viewModal.open();
			},
			async getStatements() {
				this.statements.loading = true;

				await this.sendRequest('corporate.statements.all', this.user.company_id, {
					success: response => {
						this.statements.setValue(response.data.data);
						this.statements.loaded = true;
					},
					error: error => {
						this.statements.error = error;
					}
				});

				this.statements.loading = false;
			},
			async downloadStatement(statement) {
				statement.loading = true;

				await this.sendRequest('corporate.statements.download', statement.id, {
					success: response => {
						console.log(response);
						statement.setValue(response.data.data);
						statement.loaded = true;
					},
					error: error => {
						statement.error = error;
					}
				});

				statement.loading = false;
			}
		}
	}
</script>